<template>
  <v-dialog
      v-model="show"
      :value="true"
      max-width="400"
      persistent
      @click:outside="onClickOutside"
  >
    <v-card>
      <div class="d-flex justify-end px-6 pt-4">
        <v-btn
            v-if="showClose" 
            @click="close"
            text
            icon
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>

      <v-card-text v-if="title">
        <h3 class="primary-modal-title black--text" v-html="title"></h3>
      </v-card-text>

      <slot></slot>

    </v-card>
  </v-dialog>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    title: String,
    show: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({

  }),
  methods: {
    close() {
      this.$emit('close')
    },
    onClickOutside() {
      this.$emit('clickOutside')
    },
  },
}
</script>

<style lang="sass">
.primary-modal-title
  font-size: 1.5rem
  line-height: 1.3
</style>