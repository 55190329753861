import ItemMixin from "components/form/custom/ItemMixin";
import amountTotal from "mixins/amountTotal";
import discountMixins from "mixins/discount";

export default  {
    mixins: [ItemMixin, amountTotal, discountMixins],
    methods: {
        getInitialPrice(item) {
            const {discount_config, price, shipping} = item

            const discountPrice = this.getDiscountPrice({
                discount_config,
                price: +price,
                quantity: +shipping
            })

            return this.isInDiscountRange(+shipping, discount_config) ? discountPrice : price
        },
    }
}