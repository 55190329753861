<template>
  <v-btn color="secondary" variant="tonal" @click="handleClick">再次下訂</v-btn>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    providerId: {
      type: String,
      required: true,
    },
    storeId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async handleClick() {
      this.$store.dispatch("loading/active");
      let successCount = 0;
      let failCount = 0;

      for (const item of this.orderData.items) {
        // 檢查商品是否下架
        const checkPD = await this.readApi(item.product_id);
        if (!checkPD.status) {
          failCount++;
          continue;
        }
        const res = await this.addToCart(
          item.product_id,
          item.count,
          item.shipping_unit
        );
        // 加入購物車錯誤，已被刪除的商品返回錯誤
        if (res && res.status === 400) {
          failCount++;
        } else {
          successCount++;
        }
      }

      if (successCount > 0) {
        this.$snotify.success(`${successCount} 筆品項再次加入購物車。`);
      }
      if (failCount > 0) {
        this.$snotify.error(`${failCount} 筆品項加入失敗，已下架或刪除。`);
      }
      this.$router.push({
        name: "cart",
        params: { providerId: this.providerId },
      });

      this.$store.dispatch("loading/close");
    },
    async readApi(productId) {
      return await this.$api.collection.productApi.read(productId);
    },
    async addToCart(productId, quantity, shipping_unit) {
      try {
        const params = {
          products: [
            {
              product_id: productId,
              quantity: this.$helper.transformShippingCount({
                count: quantity,
                unit: shipping_unit,
              }),
            },
          ],
        };
        await this.$api.collection.cartApi.add(
          this.storeId,
          this.providerId,
          params
        );
        await this.readCart();
        this.quantity = null;
      } catch (err) {
        console.error(err);
        return err;
      }
    },
    async readCart() {
      const res = await this.$api.collection.cartApi.read(
        this.storeId,
        this.providerId
      );
      this.$store.dispatch(`cart/setCart`, res);
    },
  },
};
</script>
