<template>
  <v-dialog v-model="open" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="handleSetDialog"> X </v-btn>
        <v-toolbar-title>商品簽收</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <form-info :value="info" class="mb-5" />
        <bar class="mb-5" type="primary">
          <template v-slot:label> 訂單編號 </template>
          <template v-slot:value>{{ order_no }}</template>
        </bar>
        <template v-if="auth">
          <block-title :hasBorder="false">圖片上傳</block-title>
          <v-row>
            <v-col cols="6" v-for="item in received_record" :key="item.id">
              <v-img :width="200" :height="200" :src="getPhotoUrl(item)"></v-img>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <block-title :hasBorder="false">圖片上傳</block-title>
          <template v-if="status == orderConstants.STATUS_SENDED">
            <p>請拍照簽收單 / 出貨單 / 貨物 等上傳至系統</p>
            <photo-selector v-model="photos" multiple :total="3" :names="names" :formKey="formKey"
              :getHasPhoto.sync="hasPhoto"></photo-selector>
            <custom-form-action-btn :action="{ key: 'submit' }" :form="formKey" label="確定簽收"
              color="primary"></custom-form-action-btn>
          </template>

          <v-row v-else>
            <v-col cols="6" v-for="item in received_record" :key="item.id">
              <v-img :width="200" :height="200" :src="getPhotoUrl(item)"></v-img>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="babel" type="text/babel">


import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue"
import photoSelector from "@/components/photoSelector/photoSelector.vue"

export default {
  components: {
    formInfo: () => import('@/components/form/custom/formInfo.vue'),
    bar: () => import('@/modules/base/components/bar/bar.vue'),
    "block-title": () => import('@/modules/base/components/blockTitle.vue'),
    customFormActionBtn,
    photoSelector
  },
  props : {
    auth: {
      type: Boolean,
    },
      openDialog : {
        type: Boolean,
        default: false
      },
      handleSetDialog : Function,
      readData: Object,
      orderConstants: Object,
      formKey : String,
  },
  data: () => ({
    open: false,
    photos : [],
    hasPhoto: false,
    notifications: false,
    sound: true,
    widgets: false
  }),
  created() {

  },
  watch :{
    openDialog: {
      handler() {
        this.open = this.openDialog
      }
    },
    // hasPhoto: {
    //   immediate: true,
    //   handler() {
    //     this.$store.dispatch(`customForm/${this.formKey}/setOtherValidate`, {
    //       key: 'photo',
    //       value: {
    //         has: this.hasPhoto,
    //         message: '圖片必填, 至少一張',
    //       }
    //     })
    //   },
    // },
  },

  computed: {
    order_no() {

      if(!this.readData) return '';
      return this.readData.order_no
    },
    status() {
      if(!this.readData) return '';
      return this.readData.status
    },
    received_record() {
      if(!this.readData) return '';
      return this.readData.received_record
    },
    names() {
      return this.photos.reduce((acc, cur, index) => {
        let name = `imgs[${index}]`
        acc.push(name)
        return acc
      }, [])
    },
    info() {
      return [
        {text: '訂單狀態', value: this.$t(`store.order.status.${this.status}`)},
      ]
    },
  },
  methods: {
     getPhotoUrl(photo) {
      return this.$helper.getPhotoUrl(photo)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
