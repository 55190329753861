<template>
  <v-dialog
    v-model="openOrderAdjustmentDialog" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="訂單調整" class="primary--text" />
        <v-btn @click="handleOrderAdjustmentDialogSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container>
        <block-title>品項: </block-title>
        <store-order-card ref="orderAdjustmentCard" v-for="(item, index) in orderCardData" :key="index" :index="index" v-model="orderCardData[index]"
          :configs="cardConfigs(index)"></store-order-card>
        <v-row class="mb-5">
          <v-col cols="6" class="py-0 pr-0">
            <number-bar title="運費" unit="元" v-model="delivery_fee" readonly name="delivery_fee" :type="'mine'"
              class="border-0 store-bg-color white--text rounded-r-0" :fixed="0"></number-bar>
          </v-col>
          <v-col cols="6" class="py-0 pl-0">
            <number-bar title="合計" unit="元" v-model="amount"
              readonly name="amount" :type="'mine'" class="border-0 store-bg-color white--text rounded-l-0" :fixed="0"></number-bar>
          </v-col>
        </v-row>
        <div class="pa-4 text-center" v-if="!isDisplayMode">
          <v-btn color="primary" outlined rounded @click="handleClickAdjustOrder">確認調整訂單</v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import orderItemMixins from "@/mixins/orderItem.js";
export default {
  mixins: [orderItemMixins],
  components: {
    "store-order-card": () => import("@/modules/base/components/priceCard/order/storeOrderCard.vue"),
    "block-title": () => import('@/modules/base/components/blockTitle.vue'),
    "number-bar": () => import('@/modules/base/components/bar/numberBar.vue'),
  },
  props: {
    openOrderAdjustmentDialog: {
      type: Boolean,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
    providerId: {
      type: String,
      required: true,
    },
    storeId: {
      type: String,
      required: true,
    },
    isDisplayMode: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Function,
      required: false,
    }
  },
  data: () => ({
    orderCardData: [],
    delivery_fee: 0,
    amount: 0
  }),
  watch : {
    orderData: {
      deep: true,
      async handler() {
        if(!this.orderData.items) return
        this.orderCardData = this.orderData.items.map(item => {
          return {
            shipping_unit: item.shipping_unit, // 出貨單位
            calculate_unit: item.calculate_unit, // 計價單位
            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
            shipping_count: this.$helper.reverseShippingCount(item.shipping, item.shipping_unit), // 出貨數量
            origin_price: item.origin_price,
            price: this.editAble ? this.getInitialPrice(item) : item.price, // 單價
            order_count: this.$helper.reverseShippingCount(item.count, item.shipping_unit), // 訂購數量 / 數量
            per_shipping_count_text: `${item.per_shipping_count}${item.calculate_unit}/${item.shipping_unit}`, //規格
            item_id: item.item_id,
            modify_count: item.modifyCount
          }
        })
        await this.$helper.delay(0.5)
        this.delivery_fee = this.orderData.delivery_fee
        this.amount = this.orderData.total_amounts
      }
    },
    orderCardData: {
      deep: true,
      async handler() {
        await this.$helper.delay(0.5)
        if(!this.$refs['orderAdjustmentCard']) return
        this.amount = Math.round(this.$refs['orderAdjustmentCard'].reduce((arr, cur) => {
          if (cur.total) arr += Number(cur.total)
          return arr
        }, this.delivery_fee))
      },
    },
    
  },
  methods: {
    handleOrderAdjustmentDialogSwitch(isOpen) {
      this.$emit('updateOrderAdjustmentDialogSwitch', isOpen)
    },
    async handleClickAdjustOrder() {
      this.$store.dispatch("loading/active")
      try {
        const requestData = {
          items: this.orderCardData.map((v => (
            {
              item_id: v.item_id,
              count: v.shipping_count,
              price: v.price
            }
          ))),
          client: 'store'
        }
        await this.$api.collection.providerApi.putOrderAdjustment(this.providerId, this.orderData.order_no, requestData);
        this.$snotify.success(`調整成功`)
        await this.updateData()
        this.handleOrderAdjustmentDialogSwitch(false)
      } catch (e) {
        this.$snotify.error(`調整訂單失敗，請稍後再試`);
      } finally{
        this.$store.dispatch("loading/close")
      }
    },
    cardConfigs(index) {
      const item = this.orderData.items[index]
      const shippingCountType = this.isDisplayMode ? '' : 'number-input'
      let configs = [
        {label: item.product, colon: false, class: 'border-b'},
        {id: "per_shipping_count_text", label: "規格", col: 6, class: "d-flex"},
        {id: "price", label: "單價",col: 6, name: `items[${index}][price]`, valueSuffix: "元", class: "d-flex"},
        {id: "shipping_count", label: "數量", col: 6, type: shippingCountType, class: "price-card-borderNone"},
        {id: "total", label: '小計', type: "total", barType: 'primary', valueSuffix: "元", col: 6, class: "d-flex"}
      ]
      return configs
    },
  },
};
</script>
